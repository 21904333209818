import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import GameListItem from "./GameListItem";
import NoGamesPlaceholder from "../placeholders/NoGamesPlaceholder";
import Loading from "../placeholders/Loading";

class GamesList extends Component {
    componentDidMount() {
        this.props.fetchGames();
    }

    //TODO: Extract to helpers
    pointsResult(gameRound, gamePoints, playerSeat) {
        return gameRound === "FINISH"
            ? gamePoints[playerSeat][0] + gamePoints[playerSeat][1] + gamePoints[playerSeat][2]
            : gamePoints[playerSeat][0] + gamePoints[playerSeat][1];
    }

    renderGames(games) {
        if (games.length > 0) {
            return games.map(game => (
                <GameListItem
                    key={game.id}
                    game_id={game.id}
                    header={
                        <Moment unix format="dddd, MMMM Do YYYY, HH:mm:ss">
                            {game.created_at}
                        </Moment>
                    }
                    round={game.round}
                    honba={game.honba}
                    players={game.players}
                    player_one_points={this.pointsResult(game.round, game.points, 0)}
                    player_two_points={this.pointsResult(game.round, game.points, 1)}
                    player_three_points={this.pointsResult(game.round, game.points, 2)}
                    player_four_points={this.pointsResult(game.round, game.points, 3)}
                    player_one_uma={game.points[0][1]}
                    player_two_uma={game.points[1][1]}
                    player_three_uma={game.points[2][1]}
                    player_four_uma={game.points[3][1]}
                    riichi={game.riichi}
                />
            ));
        } else {
            return <NoGamesPlaceholder />;
        }
    }

    render() {
        const { games, loading } = this.props.gamesList;

        if (loading) {
            return <Loading />;
        }

        return <div className="games-list">{this.renderGames(games)}</div>;
    }
}

export default GamesList;

GamesList.propTypes = {
    fetchGames: PropTypes.func,
    gamesList: PropTypes.object
};
