import React from "react";
import PropTypes from "prop-types";
import { Tab } from "../../tabs/tab";
import { Tabs } from "../../tabs/tabs";
import TableView from "../../rounds/RoundTableView";
import PlayerRoundsComparison from "../../graphs/PlayerRoundsComparison";
import { NavLink } from "react-router-dom";

function GameInstanceView(props) {
    let metadata = props.currentGame.game.metadata ? props.currentGame.game.metadata : false;

    return (
        <div className="d-flex flex-column w-100 rounds">
            {metadata ? (
                <NavLink
                    className="button button--lignt-gray"
                    to={"/app/tournaments/" + metadata.tournament_id}>
                    Go to Tournament{" "}
                </NavLink>
            ) : null}
            <Tabs>
                <Tab label="Rounds" iconClassName="fas fa-list">
                    <TableView game={props.currentGame.game} RoundsBody={props.RoundComponents} />
                </Tab>
                <Tab label="Statistics" iconClassName="fas fa-chart-line">
                    {
                        <PlayerRoundsComparison
                            rounds={props.graphs}
                            players={props.currentGame.game.players}
                            initialPoints={props.currentGame.game.settings.initial_points}
                        />
                    }
                </Tab>
            </Tabs>
        </div>
    );
}

export default GameInstanceView;

GameInstanceView.propTypes = {
    RoundComponents: PropTypes.array,
    currentGame: PropTypes.object,
    graphs: PropTypes.array
};
