import {
    FETCH_TOURNAMENTS,
    FETCH_TOURNAMENTS_FAILURE,
    FETCH_TOURNAMENTS_SUCCESS,
    RESET_TOURNAMENTS
} from "../actions/tournamentsActions";

export default function reducer(state = { tournaments: [], error: null, loading: false }, action) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_TOURNAMENTS: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_TOURNAMENTS_SUCCESS: {
            return {
                ...state,
                tournaments: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_TOURNAMENTS_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return { ...state, tournaments: [], error: error, loading: false };
        case RESET_TOURNAMENTS: // reset gamesList to initial state
            return { ...state, tournaments: [], error: null, loading: false };
    }
}
