import React, { Component } from "react";
import PropTypes from "prop-types";
import RoundUtils from "../../utils/RoundUtils";

const players = ["Player1", "Player2", "Player3", "Player4"];

class ChonboRoundView extends Component {
    isChonbo(chonboSeats, player) {
        return chonboSeats.includes(player) ? <div style={{ color: "#FF4444" }}>x_x</div> : "-";
    }

    render() {
        return (
            <tr>
                <td data-label="Round">{RoundUtils.roundParser(this.props.round_num)}</td>
                <td data-label="Outcome">{this.props.outcome}</td>
                <td data-label={this.props.players[0].name}>
                    {this.isChonbo(this.props.chonboSeats, players[0])}
                </td>
                <td data-label={this.props.players[1].name}>
                    {this.isChonbo(this.props.chonboSeats, players[1])}
                </td>
                <td data-label={this.props.players[2].name}>
                    {this.isChonbo(this.props.chonboSeats, players[2])}
                </td>
                <td data-label={this.props.players[3].name}>
                    {this.isChonbo(this.props.chonboSeats, players[3])}
                </td>
            </tr>
        );
    }
}

export default ChonboRoundView;

ChonboRoundView.propTypes = {
    chonboSeats: PropTypes.array,
    outcome: PropTypes.string,
    round_num: PropTypes.string,
    players: PropTypes.array
};
