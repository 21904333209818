import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import PlaceholderTemplate from "../../components/placeholders/PlaceholderTemplate";
import axios from "axios";
import getURLSegment from "../../utils/urlUtils";

class JoinTeam extends Component {
    getTeam() {
        return getURLSegment(window.location.pathname, 3);
    }

    joinTeam() {
        const team = this.getTeam();
        axios
            .post("/api/v1/teams/" + team + "/join", "", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then(response => {
                console.log(response);
                alert("Request successful!");
            })
            .catch(error => {
                alert("Request failed.");
                console.log(error);
            });
    }

    renderJoinTeams() {
        const team = this.getTeam();
        return <PlaceholderTemplate
            header={"Join '" + team + "' team"}
            leading={
                "If you join the team, you will be able to manage the games from the linked account but at the same time you will loose the access to any games created on this account."
            }
            body={
                <div>
                    <button className="button button--blue" onClick={this.joinTeam.bind(this)}>JOIN THE TEAM</button>
                </div>
            }
        />;
    }

    render() {
        return <div className="games-list">{this.renderJoinTeams()}</div>;
    }
}

export default JoinTeam;

JoinTeam.propTypes = {
};
