import {
    FETCH_TOURNAMENT,
    FETCH_TOURNAMENT_SUCCESS,
    FETCH_TOURNAMENT_FAILURE,
    RESET_TOURNAMENT
} from "../actions/tournamentActions";

export default function reducer(state = { tournament: null, error: null, loading: true }, action) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_TOURNAMENT: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_TOURNAMENT_SUCCESS: {
            return {
                ...state,
                tournament: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_TOURNAMENT_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return { ...state, tournament: [], error: error, loading: false };
        case RESET_TOURNAMENT: // reset currentGame to initial state
            return { ...state, tournament: [], error: null, loading: false };
    }
}
