import {
    FETCH_TOURNAMENT_DETAILS,
    FETCH_TOURNAMENT_DETAILS_SUCCESS,
    FETCH_TOURNAMENT_DETAILS_FAILURE,
    RESET_TOURNAMENT_DETAILS
} from "../actions/tournamentDetailsActions";

export default function reducer(state = { tournamentDetails: {name: ""}, error: null, loading: true }, action) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_TOURNAMENT_DETAILS: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_TOURNAMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                tournamentDetails: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_TOURNAMENT_DETAILS_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return { ...state, tournamentDetails: [], error: error, loading: false };
        case RESET_TOURNAMENT_DETAILS: // resets to initial state
            return { ...state, tournamentDetails: [], error: null, loading: false };
    }
}
