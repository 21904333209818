import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { fetchTournamentRanking } from "../../actions/tournamentRankingActions";
//TODO: create noTournamentsPlaceholder
import Loading from "../placeholders/Loading";
import ReactTable from "react-table";
import getURLSegment from "../../utils/urlUtils";
import "react-table/react-table.css";

class TournamentRanking extends Component {
    componentDidMount() {
        this.props.fetchTournamentRanking();
    }

    createCorrectObject(tournamentRanking) {
        return Object.keys(tournamentRanking).map(key => {
            let row = {},
                hanchans = {},
                places = {};
            let player = tournamentRanking[key];

            Object.keys(player.hanchans).map(key => {
                return (hanchans["hanchan_" + key] = player.hanchans[key]);
            });

            Object.keys(player.places).map(key => {
                return (places["place_" + key] = player.places[key]);
            });

            row["hanchans"] = hanchans;
            row["places"] = places;
            row["player"] = tournamentRanking[key].player;
            row["total_points"] = tournamentRanking[key].total_points;

            return row;
        });
    }

    calculateHanchans(hanchans) {
        let colHanchans = [];

        for (let iter = 0; iter < hanchans.length; iter++) {
            colHanchans.push({
                Header: "Hanchan " + (iter + 1),
                id: iter + 1,
                maxWidth: 180,
                columns: [
                    {
                        Header: "Pl.",
                        id: "place-in-rank" + iter,
                        Cell: row =>
                            row.original["hanchans"]["hanchan_" + iter]["place"] === null
                                ? ""
                                : row.original["hanchans"]["hanchan_" + iter]["place"],
                        width: 40,
                        className: "text-right"
                    },
                    {
                        Header: "Points",
                        id: "points" + iter,
                        Cell: row => (
                            <NavLink
                                to={
                                    "/app/tournaments/" +
                                    getURLSegment(window.location.pathname, 3) +
                                    "/" +
                                    row.original["hanchans"]["hanchan_" + iter]["game_id"] +
                                    "/rounds"
                                }>
                                {row.original["hanchans"]["hanchan_" + iter]["points"]}
                            </NavLink>
                        ),
                        maxWidth: 100,
                        className: "text-right"
                    }
                ]
            });
        }

        return colHanchans;
    }

    placesInHanchan(places) {
        return [
            {
                Header: "Placing in hanchans",
                columns: [
                    {
                        Header: "1st place",
                        id: "1stPlace",
                        Cell: places[0],
                        className: "text-center"
                    },
                    {
                        Header: "2nd place",
                        id: "2ndPlace",
                        Cell: places[1],
                        className: "text-center"
                    },
                    {
                        Header: "3rd place",
                        id: "3rdPlace",
                        Cell: places[2],
                        className: "text-center"
                    },
                    {
                        Header: "4th place",
                        id: "4thPlace",
                        Cell: places[3],
                        className: "text-center"
                    }
                ]
            }
        ];
    }

    render() {
        const { tournamentRanking, loading } = this.props.tournamentRanking;

        if (loading) {
            return <Loading />;
        }

        let columns = [];

        if (tournamentRanking[0]) {
            columns = [
                {
                    id: "place",
                    Header: "Pl.",
                    Cell: row => <span>{row.index + 1}</span>,
                    width: 40
                },
                {
                    id: "playerName",
                    Header: "Player Name",
                    accessor: "player.name",
                    minWidth: 250
                }
            ];

            this.calculateHanchans(tournamentRanking[0].hanchans).forEach(element => {
                columns.push(element);
            });

            this.createCorrectObject(tournamentRanking);

            const points = {
                Header: "Points",
                accessor: "total_points",
                Cell: props => <span className="number">{props.value}</span>,
                className: "text-right",
                maxWidth: 100
            };

            columns.push(points);
        }

        return (
            <div className="ranking">
                <ReactTable
                    data={this.createCorrectObject(tournamentRanking)}
                    columns={columns}
                    showPagination={false}
                    defaultPageSize={tournamentRanking.length}
                    className="-striped -highlight"
                    sortable={true}
                    resizable={false}
                    defaultSorted={[
                        {
                            id: "place-in-rank",
                            desc: false
                        }
                    ]}
                    SubComponent={row => {
                        const rowData = Object.keys(row.original.places).map(key => {
                            return row.original.places[key];
                        });

                        return (
                            <ReactTable
                                data={rowData}
                                columns={this.placesInHanchan(rowData)}
                                defaultPageSize={1}
                                showPagination={false}
                            />
                        );
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tournamentRanking: state.tournamentRanking
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTournamentRanking: () => {
            dispatch(fetchTournamentRanking());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TournamentRanking);

TournamentRanking.propTypes = {
    fetchTournamentRanking: PropTypes.func,
    tournamentRanking: PropTypes.object,
    value: PropTypes.number
};
