import React from "react";
import { Route, Router } from "react-router-dom";
import history from "../history";

import Header from "./Header";
import MainMenu from "./MainMenu";
import AuthService from "../utils/auth/AuthService";
import GamesList from "../containers/GamesListContainer";
import JoinTeam from "../containers/JoinTeamContainer";
import RoundsList from "../containers/RoundsListContainer";
import Callback from "../components/Callback/Callback";
import UnderConstruction from "../components/placeholders/UnderConstruction";
import TournamentsList from "../containers/TournamentsListContainer";
import Tournament from "../containers/TournamentContainer";

const auth = new AuthService();

const handleAuthentication = ({ location }) => {
    if (/access_token|id_token|error/.test(location.hash)) {
        auth.handleAuthentication();
    }
};

function cleanUpLocalStorage() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("profile_picture");
    localStorage.removeItem("user_name");
}

const logIn = function() {
    auth.login();
    return <Callback />;
};

//TODO: Extract all visual staff into a separate component. Leave only routes.
const MainView = () => {
    if (!auth.isAuthenticated()) {
        cleanUpLocalStorage();
    }
    return (
        <Router history={history}>
            <div className="main-view">
                <MainMenu />
                <main className="main-view--column">
                    <Header auth={auth} />
                    <div className="main-view__content">
                        <Route
                            exact
                            path="/app/games"
                            render={props =>
                                !auth.isAuthenticated() ? (
                                    logIn()
                                ) : (
                                    <GamesList auth={auth} {...props} />
                                )
                            }
                        />
                        <Route
                            path="/app/games/private/:gameId/rounds"
                            render={props =>
                                !auth.isAuthenticated() ? (
                                    logIn()
                                ) : (
                                    <RoundsList auth={auth} {...props} />
                                )
                            }
                        />
                        <Route
                            path="/app/callback"
                            render={props => {
                                handleAuthentication(props);
                                return <Callback {...props} />;
                            }}
                        />
                        <Route
                            exact
                            path="/app/statistics"
                            render={props => <UnderConstruction {...props} />}
                        />
                        <Route
                            exact
                            path="/app/tournaments"
                            render={props => <TournamentsList auth={auth} {...props} />}
                        />
                        <Route
                            exact
                            path="/app/tournaments/:tournamentId"
                            render={props => <Tournament auth={auth} {...props} />}
                        />
                        <Route
                            path="/app/tournaments/:tournamentId/:gameId/rounds"
                            render={props => <RoundsList auth={auth} {...props} />}
                        />
                        <Route
                            exact
                            path="/app/Settings"
                            render={props => <UnderConstruction {...props} />}
                        />
                        <Route
                            exact
                            path="/app/join/:token"
                            render={props =>
                                !auth.isAuthenticated() ? (
                                    logIn()
                                ) : (
                                    <JoinTeam auth={auth} {...props} />
                                )
                            }
                        />
                    </div>
                </main>
            </div>
        </Router>
    );
};

export default MainView;
