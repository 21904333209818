import React, { Component } from "react";
import PropTypes from "prop-types";
import { fetchTournamentAchievements } from "../../actions/tournamentAchievementsActions";
import { connect } from "react-redux";
import Loading from "../placeholders/Loading";

class TournamentAchievements extends Component {
    componentDidMount() {
        this.props.fetchTournamentAchievements();
    }

    achievementsListItem(achievement, index) {
        return (
            <div className="achievement" key={index}>
                {/*<div className="card text-center">*/}
                <div className="achievement__body">
                    <div className="achievement__title">{achievement.name}</div>
                    <div className="achievement__subtitle">
                        {achievement.description}: <b>{achievement.value}</b>
                    </div>
                    <hr className="separator separator--dark" />
                    {achievement.players.map((player, index) => {
                        return (
                            <div className="achievement__text" key={index}>
                                {player.name}
                                <br />
                            </div>
                        );
                    })}
                </div>
                {/*</div>*/}
            </div>
        );
    }

    renderAchievementsList(tournamentAchievements) {
        return tournamentAchievements.map((achievement, index) => {
            return this.achievementsListItem(achievement, index);
        });
    }

    render() {
        const { tournamentAchievements, loading } = this.props.tournamentAchievements;

        if (loading) {
            return <Loading />;
        }

        return (
            <div className="achievements">
                {this.renderAchievementsList(tournamentAchievements)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tournamentAchievements: state.tournamentAchievements
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTournamentAchievements: () => {
            dispatch(fetchTournamentAchievements());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TournamentAchievements);

TournamentAchievements.propTypes = {
    fetchTournamentAchievements: PropTypes.func,
    tournamentAchievements: PropTypes.object
};
