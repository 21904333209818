import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Header extends Component {
    login() {
        this.props.auth.login();
    }

    logout() {
        this.props.auth.logout();
    }

    render() {
        const { isAuthenticated } = this.props.auth;

        return (
            <header className="app-header">
                <div className="page-name">{this.props.pageName}</div>
                {!isAuthenticated && (
                    <button className="button button--grey" onClick={this.login.bind(this)}>
                        Login
                    </button>
                )}
                {isAuthenticated && (
                    <button className="button button--grey" onClick={this.logout.bind(this)}>
                        Logout
                    </button>
                )}
            </header>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageName: state.tournamentDetails.tournamentDetails.name
    };
};

export default connect(mapStateToProps)(Header);

Header.propTypes = {
    auth: PropTypes.object
};
