import { connect } from "react-redux";
import { fetchTournaments } from "../actions/tournamentsActions";
import TournamentsList from "../components/tournaments/TournamentsList";

const mapStateToProps = state => {
    return {
        tournamentsList: state.tournamentsList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTournamentsList: () => {
            dispatch(fetchTournaments());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TournamentsList);
