import React from "react";
import PropTypes from "prop-types";
import RoundUtils from "../../utils/RoundUtils";
import { NavLink } from "react-router-dom";

function GameListItem(props) {
    return (
        <NavLink
            className="game-list-item"
            to={
                props.tournament_id
                    ? "/app/tournaments/" + props.tournament_id + "/" + props.game_id + "/rounds"
                    : "/app/games/private/" + props.game_id + "/rounds"
            }>
            <div className="game-list-item__info">
                <div className="game-list-item__info__header">{props.header}</div>
                <hr className="separator separator--dark" />
                <span className="game-list-item__info__details">
                    Players: {props.players[0].name}: {props.player_one_points},{" "}
                    {props.players[1].name}: {props.player_two_points}, {props.players[2].name}:{" "}
                    {props.player_three_points}, {props.players[3].name}: {props.player_four_points}
                </span>
            </div>
            <div className="game-list-item__round">{RoundUtils.roundParser(props.round)}</div>
        </NavLink>
    );
}

export default GameListItem;

GameListItem.propTypes = {
    game_id: PropTypes.string,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    player_four_points: PropTypes.number,
    player_one_points: PropTypes.number,
    player_three_points: PropTypes.number,
    player_two_points: PropTypes.number,
    players: PropTypes.array,
    round: PropTypes.string,
    tournament_id: PropTypes.string
};
