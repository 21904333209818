import { combineReducers } from "redux";

import gamesData from "./gamesReducer";
import roundsList from "./roundsReducer";
import gameInfo from "./gameReducer";
import tournamentsList from "./tournamentsReducer";
import tournamentInfo from "./tournamentReducer";
import tournamentDetails from "./tournamentDetailsReducer";
import tournamentTimer from "./tournamentTimerReducer";
import tournamentRanking from "./tournamentRankingReducer";
import tournamentAchievements from "./tournamentAchievementsReducer";

export default combineReducers({
    gamesData,
    roundsList,
    gameInfo,
    tournamentsList,
    tournamentInfo,
    tournamentDetails,
    tournamentTimer,
    tournamentRanking,
    tournamentAchievements
});
