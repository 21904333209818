import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Tab } from "../tabs/tab";
import { Tabs } from "../tabs/tabs";
//TODO: create noTournamentsPlaceholder
// import NoGamesPlaceholder from "../placeholders/NoGamesPlaceholder"
import Loading from "../placeholders/Loading";
import HanchansList from "./HanchansList";
import TournamentRanking from "./TournamentRanking";
import TournamentAchievements from "./TournamentAchievements";
import TournamentNotFinished from "../placeholders/TournamentNotFinished";
import { FullScreenTimer } from "../common/timer/FullScreenTimer";

class Tournament extends Component {
    state = { show: false };

    componentDidMount() {
        this.props.fetchTournamentDetails();
        this.props.fetchTournamentTimer();
    }

    componentWillUnmount() {
        this.props.resetTournamentDetails();
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    render() {
        const { tournamentDetails, loading } = this.props.tournamentDetails;
        const { tournamentTimer } = this.props.tournamentTimer;

        if (loading) {
            return <Loading />;
        }

        return (
            <div className="tournament">
                {tournamentTimer.finishes_at &&
                tournamentTimer.starts_at &&
                Number(tournamentTimer["x-mahjongtracker-timestamp"]) < tournamentTimer.finishes_at ? (
                    <Fragment>
                        <button className="button button--blue" onClick={this.showModal}>
                            Show timer
                        </button>
                        <FullScreenTimer
                            show={this.state.show}
                            handleClose={this.hideModal}
                            startsAt={tournamentTimer.starts_at}
                            finishesAt={tournamentTimer.finishes_at}
                            serverTime={Number(tournamentTimer["x-mahjongtracker-timestamp"])}
                        />
                    </Fragment>
                ) : null}
                <Tabs>
                    <Tab label="Hanchans" iconClassName="fas fa-list">
                        <HanchansList />
                    </Tab>
                    <Tab label="Ranking" iconClassName="fas fa-chart-line">
                        <TournamentRanking />
                    </Tab>
                    <Tab label="Achievements" iconClassName="fas fa-trophy">
                        {tournamentDetails.finished ? (
                            <TournamentAchievements />
                        ) : (
                            <TournamentNotFinished />
                        )}
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default Tournament;

Tournament.propTypes = {
    fetchTournamentDetails: PropTypes.func,
    fetchTournamentTimer: PropTypes.func,
    resetTournamentDetails: PropTypes.func
};
