import React from "react";
import PlaceholderTemplate from "./PlaceholderTemplate";

const NoGamesPlaceholder = () => {
    return (
        <PlaceholderTemplate
            header={"There has been no games played yet."}
            leading={
                "To see your games here please download our app first and play Riichi with ease."
            }
            body={
                <div>
                    <a className="center-placeholder__link" href="https://play.google.com/store/apps/details?id=com.mahjongtracker.androidapp">
                        <i className="fab fa-google-play fa-5x" />
                    </a>
                </div>
            }
        />
    );
};

export default NoGamesPlaceholder;
