import React from "react";
import PropTypes from "prop-types";

const PlaceholderTemplate = props => {
    return (
        <div className="center-placeholder">
            <h1 className="display-3 text-center">{props.header}</h1>
            <p className="lead text-center">{props.leading}</p>
            <div>{props.body}</div>
        </div>
    );
};

PlaceholderTemplate.propTypes = {
    header: PropTypes.string,
    leading: PropTypes.string,
    body: PropTypes.element
};

export default PlaceholderTemplate;
