import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

//TODO Extract user info into a separate component?

const MainMenu = () => {
    return (
        <Fragment>
            <nav className="menu" tabIndex="0">
                <div className="smartphone-menu-trigger" />
                <div className="overlay" tabIndex="0" />
                <div className="menu__content">
                    <a href="/" className="branding" />
                    {localStorage.getItem("profile_picture") ? (
                        <header className="avatar">
                            <img src={localStorage.getItem("profile_picture")} alt="..." />
                            <div className="avatar__text">
                                Welcome, {localStorage.getItem("user_name")}!
                            </div>
                            <hr className="separator separator--light" />
                        </header>
                    ) : (
                        <div />
                    )}
                    <ul>
                        <NavLink className={"side-nav-link"} tabIndex="0" to="/app/games">
                            <i className="fas fa-dice icon-games" />
                            <span>Games</span>
                        </NavLink>
                        <NavLink
                            className={"side-nav-link icon-statistics"}
                            tabIndex="0"
                            to="/app/statistics"
                            exact>
                            <i className="fas fa-chart-line icon-statistics" />
                            <span>Statistics</span>
                        </NavLink>
                        <NavLink
                            className={"side-nav-link icon-tournaments"}
                            tabIndex="0"
                            to="/app/tournaments">
                            <i className="fas fa-trophy icon-tournaments" />
                            <span>Tournaments</span>
                        </NavLink>
                        <NavLink
                            className={"side-nav-link icon-settings"}
                            tabIndex="0"
                            to="/app/settings"
                            exact>
                            <i className="fas fa-sliders-h icon-settings" />
                            <span>Settings</span>
                        </NavLink>
                    </ul>
                </div>
            </nav>
        </Fragment>
    );
};

export default MainMenu;
