import { applyMiddleware, createStore } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "redux-logger";
import ReduxThunk from "redux-thunk";

import reducer from "./reducers";

const environment = function() {
    if (process.env.NODE_ENV !== "production") {
        return composeWithDevTools(applyMiddleware(ReduxThunk, logger));
    } else {
        return composeWithDevTools(applyMiddleware(ReduxThunk));
    }
};

export default createStore(reducer, environment());
