import React, { Component } from "react";
import PropTypes from "prop-types";

class UmaPointsView extends Component {
    render() {
        return (
            <tr className="tr--gray">
                <td data-label="End of game">Uma</td>
                <td />
                <td data-label={this.props.game.players[0].name}>{this.props.game.points[0][1]}</td>
                <td data-label={this.props.game.players[1].name}>{this.props.game.points[1][1]}</td>
                <td data-label={this.props.game.players[2].name}>{this.props.game.points[2][1]}</td>
                <td data-label={this.props.game.players[3].name}>{this.props.game.points[3][1]}</td>
            </tr>
        );
    }
}

export default UmaPointsView;

UmaPointsView.propTypes = {
    game: PropTypes.object
};
