import React, { Component } from "react";
import PropTypes from "prop-types";
import GameListItem from "../games/GameListItem";
import getURLSegment from "../../utils/urlUtils";

class Hanchan extends Component {
    //TODO: Extract to helpers
    pointsResult(gameRound, gamePoints, playerSeat) {
        return gameRound === "FINISH"
            ? gamePoints[playerSeat][0] + gamePoints[playerSeat][1] + gamePoints[playerSeat][2]
            : gamePoints[playerSeat][0] + gamePoints[playerSeat][1];
    }

    renderGames(tables) {
        if (tables.length > 0) {
            return tables.map(table => (
                <GameListItem
                    key={table.game.id}
                    game_id={table.game.id}
                    header={"Table " + table.id}
                    round={table.game.round}
                    honba={table.game.honba}
                    players={table.game.players}
                    player_one_points={this.pointsResult(table.game.round, table.game.points, 0)}
                    player_two_points={this.pointsResult(table.game.round, table.game.points, 1)}
                    player_three_points={this.pointsResult(table.game.round, table.game.points, 2)}
                    player_four_points={this.pointsResult(table.game.round, table.game.points, 3)}
                    player_one_uma={table.game.points[0][1]}
                    player_two_uma={table.game.points[1][1]}
                    player_three_uma={table.game.points[2][1]}
                    player_four_uma={table.game.points[3][1]}
                    riichi={table.game.riichi}
                    tournament_id={getURLSegment(window.location.pathname, 3)}
                />
            ));
        } else {
            return null;
        }
    }

    render() {
        return (
            <div>
                <input type="checkbox" name={"section"} id={"section-" + this.props.hanchanId} />
                <label htmlFor={"section-" + this.props.hanchanId} className="hanchan-list__label">
                    Hanchan {this.props.hanchanId}
                </label>
                <div className="accordion__content">
                    <div className="accordion__body">{this.renderGames(this.props.tables)}</div>
                </div>
            </div>
        );
    }
}

export default Hanchan;

Hanchan.propTypes = {
    hanchanId: PropTypes.number,
    tables: PropTypes.arrayOf(PropTypes.object)
};
