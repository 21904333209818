import {
    FETCH_RANKING,
    FETCH_RANKING_FAILURE,
    FETCH_RANKING_SUCCESS,
    RESET_RANKING
} from "../actions/tournamentRankingActions";

export default function reducer(
    state = { tournamentRanking: [], error: null, loading: false },
    action
) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_RANKING: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_RANKING_SUCCESS: {
            return {
                ...state,
                tournamentRanking: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_RANKING_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return {
                ...state,
                tournamentRanking: [],
                error: error,
                loading: false
            };
        case RESET_RANKING: // reset gamesList to initial state
            return {
                ...state,
                tournamentRanking: [],
                error: null,
                loading: false
            };
    }
}
