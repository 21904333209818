import axios from "axios";
import getURLSegment from "../utils/urlUtils";

export const FETCH_TOURNAMENT = "FETCH_TOURNAMENT";
export const FETCH_TOURNAMENT_SUCCESS = "FETCH_TOURNAMENT_SUCCESS";
export const FETCH_TOURNAMENT_FAILURE = "FETCH_TOURNAMENT_FAILURE";
export const RESET_TOURNAMENT = "RESET_TOURNAMENT";

export function fetchTournament() {
    return dispatch => {
        dispatch(fetchingTournament());
        return axios
            .get("/api/v1/tournaments/" + getURLSegment(window.location.pathname, 3) + "/games")
            .then(response => {
                dispatch(fetchTournamentSuccess(response.data));
            })
            .catch(error => {
                dispatch(fetchTournamentFailure(error));
            });
    };
}

export function fetchingTournament() {
    return {
        type: FETCH_TOURNAMENT
    };
}

export function fetchTournamentSuccess(tournament) {
    return {
        type: FETCH_TOURNAMENT_SUCCESS,
        payload: tournament
    };
}

export function fetchTournamentFailure(error) {
    return {
        type: FETCH_TOURNAMENT_FAILURE,
        payload: error
    };
}
