import axios from "axios";
import getURLSegment from "../utils/urlUtils";

export const FETCH_GAME = "FETCH_GAME";
export const FETCH_GAME_SUCCESS = "FETCH_GAME_SUCCESS";
export const FETCH_GAME_FAILURE = "FETCH_GAME_FAILURE";
export const RESET_GAME = "RESET_GAME";

export function fetchGame() {
    return dispatch => {
        dispatch(fetchingGame());
        if (localStorage.getItem("access_token")) {
            return axios
                .get("/api/v1/games/" + getURLSegment(window.location.pathname, 4), {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("access_token")
                    }
                })
                .then(response => {
                    dispatch(fetchGameSuccess(response.data));
                })
                .catch(error => {
                    dispatch(fetchGameFailure(error));
                });
        } else {
            return axios
                .get("/api/v1/games/" + getURLSegment(window.location.pathname, 4))
                .then(response => {
                    dispatch(fetchGameSuccess(response.data));
                })
                .catch(error => {
                    dispatch(fetchGameFailure(error));
                });
        }
    };
}

export function fetchingGame() {
    return {
        type: FETCH_GAME
    };
}

export function fetchGameSuccess(game) {
    return {
        type: FETCH_GAME_SUCCESS,
        payload: game
    };
}

export function fetchGameFailure(error) {
    return {
        type: FETCH_GAME_FAILURE,
        payload: error
    };
}
