import axios from "axios";
import getURLSegment from "../utils/urlUtils";

export const FETCH_TOURNAMENT_TIMER = "FETCH_TOURNAMENT_TIMER";
export const FETCH_TOURNAMENT_TIMER_SUCCESS = "FETCH_TOURNAMENT_TIMER_SUCCESS";
export const FETCH_TOURNAMENT_TIMER_FAILURE = "FETCH_TOURNAMENT_TIMER_FAILURE";
export const RESET_TOURNAMENT_TIMER = "RESET_TOURNAMENT_TIMER";

export function fetchTournamentTimer() {
    return dispatch => {
        dispatch(fetchingTournamentTimer());
        return axios
            .get("/api/v1/tournaments/" + getURLSegment(window.location.pathname, 3) + "/timer/")
            .then(response => {
                dispatch(
                    fetchTournamentTimerSuccess(Object.assign(response.data, response.headers))
                );
            })
            .catch(error => {
                dispatch(fetchTournamentTimerFailure(error));
            });
    };
}

export function fetchingTournamentTimer() {
    return {
        type: FETCH_TOURNAMENT_TIMER
    };
}

export function fetchTournamentTimerSuccess(TournamentTimer) {
    return {
        type: FETCH_TOURNAMENT_TIMER_SUCCESS,
        payload: TournamentTimer
    };
}

export function fetchTournamentTimerFailure(error) {
    return {
        type: FETCH_TOURNAMENT_TIMER_FAILURE,
        payload: error
    };
}

export function resetTournamentTimer() {
    return {
        type: RESET_TOURNAMENT_TIMER
    };
}
