import {
    FETCH_GAME,
    FETCH_GAME_FAILURE,
    FETCH_GAME_SUCCESS,
    RESET_GAME
} from "../actions/gameActions";

export default function reducer(state = { game: null, error: null, loading: true }, action) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_GAME: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_GAME_SUCCESS: {
            return {
                ...state,
                game: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_GAME_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return { ...state, game: [], error: error, loading: false };
        case RESET_GAME: // reset currentGame to initial state
            return { ...state, game: [], error: null, loading: false };
    }
}
