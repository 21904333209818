import axios from "axios";

export const FETCH_GAMES = "FETCH_GAMES";
export const FETCH_GAMES_SUCCESS = "FETCH_GAMES_SUCCESS";
export const FETCH_GAMES_FAILURE = "FETCH_GAMES_FAILURE";
export const RESET_GAMES = "RESET_GAMES";

export function fetchGames() {
    return dispatch => {
        dispatch(fetchingGames());
        return axios
            .get("/api/v1/games?show_all=1", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then(response => {
                dispatch(fetchGamesSuccess(response.data));
            })
            .catch(error => {
                dispatch(fetchGamesFailure(error));
            });
    };
}

export function fetchingGames() {
    return {
        type: FETCH_GAMES
    };
}

export function fetchGamesSuccess(games) {
    return {
        type: FETCH_GAMES_SUCCESS,
        payload: games
    };
}

export function fetchGamesFailure(error) {
    return {
        type: FETCH_GAMES_FAILURE,
        payload: error
    };
}
