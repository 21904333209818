import axios from "axios";
import getURLSegment from "../utils/urlUtils";

export const FETCH_TOURNAMENT_DETAILS = "FETCH_TOURNAMENT_DETAILS";
export const FETCH_TOURNAMENT_DETAILS_SUCCESS = "FETCH_RESET_TOURNAMENT_DETAILS_SUCCESS";
export const FETCH_TOURNAMENT_DETAILS_FAILURE = "FETCH_RESET_TOURNAMENT_DETAILS_FAILURE";
export const RESET_TOURNAMENT_DETAILS = "RESET_TOURNAMENT_DETAILS";

export function fetchTournamentDetails() {
    return dispatch => {
        dispatch(fetchingTournamentDetails());
        return axios
            .get("/api/v1/tournaments/" + getURLSegment(window.location.pathname, 3))
            .then(response => {
                dispatch(fetchTournamentDetailsSuccess(response.data));
            })
            .catch(error => {
                dispatch(fetchTournamentDetailsFailure(error));
            });
    };
}

export function fetchingTournamentDetails() {
    return {
        type: FETCH_TOURNAMENT_DETAILS
    };
}

export function fetchTournamentDetailsSuccess(tournamentDetails) {
    return {
        type: FETCH_TOURNAMENT_DETAILS_SUCCESS,
        payload: tournamentDetails
    };
}

export function fetchTournamentDetailsFailure(error) {
    return {
        type: FETCH_TOURNAMENT_DETAILS_FAILURE,
        payload: error
    };
}

export function resetTournamentDetails() {
    return {
        type: RESET_TOURNAMENT_DETAILS
    }
}
