import history from "../../history";
import auth0 from "auth0-js";
import { AUTH_CONFIGS } from "./auth0-variables";
import { decodeIdToken } from "../jwtHelper";
import { startsWith } from "auth0-lock/lib/utils/string_utils";

//TODO: Delete console logs before release, cleanup the code

export default class Auth {
    tokenRenewalTimeout;

    auth_conf = AUTH_CONFIGS[process.env.REACT_APP_ENV];

    auth0 = new auth0.WebAuth({
        domain: this.auth_conf.domain,
        clientID: this.auth_conf.clientId,
        redirectUri: this.auth_conf.callbackUrl,
        audience: this.auth_conf.apiUrl,
        responseType: "token id_token",
        scope: "openid profile"
    });

    constructor() {
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.scheduleRenewal();
    }

    login() {
        localStorage.setItem("redirect_after_login", window.location.pathname);
        this.clearOldNonces();
        this.auth0.authorize();
    }

    handleAuthentication() {
        //JsonWebToken Signature Algorithm should be set to RS256 in client settings on auth0 dashboard.
        this.auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
                history.push(localStorage.getItem("redirect_after_login"));
                localStorage.removeItem("redirect_after_login");
            } else if (err) {
                history.push("/"); //TODO implement something wrong happened page
                console.log(err);
                console.log(`Error: ${err.error}. Check the console for further details.`);
            }
        });
    }

    clearOldNonces() {
        Object.keys(localStorage).forEach(key => {
            if (!startsWith(key, "com.auth0.auth")) return;
            localStorage.removeItem(key);
        });
    }

    setSession(authResult) {
        // Set the time that the access token will expire at
        let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
        localStorage.setItem("access_token", authResult.accessToken);
        localStorage.setItem("id_token", authResult.idToken);
        localStorage.setItem("expires_at", expiresAt);
        this.setUserInfo(authResult.idTokenPayload.nickname);

        this.scheduleRenewal();
    }

    logout() {
        // Clear access token and ID token from local storage
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("profile_picture");
        localStorage.removeItem("user_name");
        this.userProfile = null;
        clearTimeout(this.tokenRenewalTimeout);
        // navigate to the landing page after logout
        this.auth0.logout({
            returnTo: this.auth_conf.logoutReturnURL
        });
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
        return new Date().getTime() < expiresAt;
    }

    getAccessToken() {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            throw new Error("No access token found");
        }
        return accessToken;
    }

    getProfile(cb) {
        let accessToken = this.getAccessToken();
        this.auth0.client.userInfo(accessToken, (err, profile) => {
            if (profile) {
                this.userProfile = profile;
            }
            cb(err, profile);
        });
    }

    setUserInfo(nickname) {
        let decodedIdToken = decodeIdToken(localStorage.getItem("id_token"));

        localStorage.setItem("profile_picture", decodedIdToken.picture);
        if (typeof decodedIdToken.given_name !== "undefined") {
            localStorage.setItem("user_name", decodedIdToken.given_name);
        } else {
            localStorage.setItem("user_name", nickname);
        }
    }

    renewToken() {
        this.auth0.checkSession(
            {
                audience: this.auth_conf.apiUrl,
                scope: "openid profile",
                redirectUri: `${window.location.origin}`
            },
            (err, authResult) => {
                if (err) {
                    console.log(
                        `Could not get a new token (${err.error}: ${err.error_description}).`
                    );
                } else {
                    this.setSession(authResult);
                }
            }
        );
    }

    scheduleRenewal() {
        const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
        const delay = expiresAt - Date.now();
        if (delay > 0) {
            this.tokenRenewalTimeout = setTimeout(() => {
                this.renewToken();
            }, delay);
        }
    }
}
