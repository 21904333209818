import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

let countdown;

class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayTimeLeft: "",
            initialDisplayTime: "",
            displayTimeBefore: ""
        };
    }

    calsulateTimeBeforeStart(startsAt, actualTime) {
        return startsAt > actualTime ? startsAt - actualTime : 0;
    }

    calculateTimeBeforeFinish(finishesAt, actualTime) {
        return finishesAt > actualTime ? finishesAt - actualTime : 0;
    }

    formatTime(hoursLeft, minutesLeft, remainSecondsLeft) {
        const hours = `${hoursLeft < 10 ? "0" : ""}${hoursLeft}`;
        const minutes = `${minutesLeft < 10 ? "0" : ""}${minutesLeft}`;
        const seconds = `${remainSecondsLeft < 10 ? "0" : ""}${remainSecondsLeft}`;
        return `${hours}:${minutes}:${seconds}`;
    }

    setInitialTimerTime(hanchanDuration) {
        const minutes = Math.floor(hanchanDuration / 60);
        const hours = Math.floor(minutes / 60);
        const seconds = hanchanDuration % 60;
        this.setState({
            initialDisplayTime: this.formatTime(
                hours,
                Math.floor(minutes % 60),
                seconds
            )
        });
    }

    displayTimeLeft(seconds) {
        const minutesLeft = Math.floor(seconds / 60);
        const hoursLeft = Math.floor(minutesLeft / 60);
        const remainSecondsLeft = seconds % 60;
        this.setState({
            displayTimeLeft: this.formatTime(
                hoursLeft,
                Math.floor(minutesLeft % 60),
                remainSecondsLeft
            )
        });
    }

    displayTimeBefore(seconds) {
        const minutesLeft = Math.floor(seconds / 60);
        const hoursLeft = Math.floor(minutesLeft / 60);
        const remainSecondsLeft = seconds % 60;
        this.setState({
            displayTimeBefore: this.formatTime(
                hoursLeft,
                Math.floor(minutesLeft % 60),
                remainSecondsLeft
            )
        });
    }

    renderTimer(startsAt, finishesAt, serverTime) {
        clearInterval(countdown);
        const secondsBeforeStart = this.calsulateTimeBeforeStart(startsAt, serverTime);
        const secondsUntilFinish = this.calculateTimeBeforeFinish(finishesAt, serverTime);
        const hanchanDuration = finishesAt - startsAt;
        const now = Date.now();
        const then = now + secondsUntilFinish * 1000;
        this.displayTimeLeft(secondsUntilFinish - secondsBeforeStart);
        this.displayTimeBefore(secondsBeforeStart);
        this.setInitialTimerTime(hanchanDuration);

        countdown = setInterval(() => {
            const secondsLeft = Math.round((then - Date.now()) / 1000);

            if (secondsLeft < 0) {
                clearInterval(countdown);
                return;
            }

            if (secondsLeft >= hanchanDuration) {
                this.displayTimeBefore(secondsLeft - hanchanDuration);
            } else {
                this.displayTimeLeft(secondsLeft);
            }
        }, 1000);
    }

    componentDidMount() {
        this.renderTimer(this.props.startsAt, this.props.finishesAt, this.props.serverTime);
    }

    render() {
        const { displayTimeLeft, displayTimeBefore, initialDisplayTime } = this.state;
        const { timerWrapperClass, timerClass } = this.props;

        const audio = new Audio("/app/gong2.mp3");

        if (displayTimeLeft === "00:00:00" && this.props.playAudio) {
            audio.play();
        }

        if (
            displayTimeBefore === "00:00:00" &&
            displayTimeLeft === initialDisplayTime &&
            this.props.playAudio
        ) {
            audio.play();
        }

        return (
            <Fragment>
                <div className={timerWrapperClass}>
                    {this.state.displayTimeBefore !== "00:00:00" ? (
                        <Fragment>
                            <div className="full-screen-timer__text">Hanchan will start in:</div>
                            <div className={timerClass}>{displayTimeBefore || "00:00:00"}</div>
                        </Fragment>
                    ) : (
                        <div className={timerClass}>{displayTimeLeft || "00:00:00"}</div>
                    )}
                </div>
            </Fragment>
        );
    }
}

Timer.propTypes = {
    timerWrapperClass: PropTypes.string,
    timerClass: PropTypes.string,
    seconds: PropTypes.number,
    startsAt: PropTypes.number,
    finishesAt: PropTypes.number,
    serverTime: PropTypes.number,
    playAudio: PropTypes.bool
};

Timer.defaultProps = {
    isDisplayEndTime: false,
    isDisplayResetButton: false
};

export default Timer;
