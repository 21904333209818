import axios from "axios";
import getURLSegment from "../utils/urlUtils";

export const FETCH_TOURNAMENT_ACHIEVEMENTS = "FETCH_TOURNAMENT_ACHIEVEMENTS";
export const FETCH_TOURNAMENT_ACHIEVEMENTS_SUCCESS = "FETCH_TOURNAMENT_ACHIEVEMENTS_SUCCESS";
export const FETCH_TOURNAMENT_ACHIEVEMENTS_FAILURE = "FETCH_TOURNAMENT_ACHIEVEMENTS_FAILURE";
export const RESET_TOURNAMENT_ACHIEVEMENTS = "RESET_TOURNAMENT_ACHIEVEMENTS";

export function fetchTournamentAchievements() {
    return dispatch => {
        dispatch(fetchingTournamentAchievements());
        return axios
            .get(
                "/api/v1/tournaments/" +
                    getURLSegment(window.location.pathname, 3) +
                    "/achievements"
            )
            .then(response => {
                dispatch(fetchTournamentAchievementsSuccess(response.data));
            })
            .catch(error => {
                dispatch(fetchTournamentAchievementsFailure(error));
            });
    };
}

export function fetchingTournamentAchievements() {
    return {
        type: FETCH_TOURNAMENT_ACHIEVEMENTS
    };
}

export function fetchTournamentAchievementsSuccess(achievements) {
    return {
        type: FETCH_TOURNAMENT_ACHIEVEMENTS_SUCCESS,
        payload: achievements
    };
}

export function fetchTournamentAchievementsFailure(error) {
    return {
        type: FETCH_TOURNAMENT_ACHIEVEMENTS_FAILURE,
        payload: error
    };
}
