import { connect } from "react-redux";
import {
    fetchTournamentDetails,
    resetTournamentDetails
} from "../actions/tournamentDetailsActions";
import { fetchTournamentTimer, resetTournamentTimer } from "../actions/tournamentTimerActions";
import Tournament from "../components/tournaments/Tournament";

const mapStateToProps = state => {
    return {
        tournamentDetails: state.tournamentDetails,
        tournamentTimer: state.tournamentTimer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTournamentDetails: () => {
            dispatch(fetchTournamentDetails());
        },
        resetTournamentDetails: () => {
            dispatch(resetTournamentDetails());
        },
        fetchTournamentTimer: () => {
            dispatch(fetchTournamentTimer());
        },
        resetTournamentTimer: () => {
            dispatch(resetTournamentTimer());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tournament);
