import React, { Component } from "react";
import PropTypes from "prop-types";
import Round from "./Round";
import GameInstanceView from "../games/gameDetails/GameInstance";
import Loading from "../placeholders/Loading";

class RoundsList extends Component {
    constructor(props) {
        super(props);
        this.cumulativeSum = [0, 0, 0, 0];
        this.graphs = [];
    }

    componentDidMount() {
        this.props.loadGameDetails();
    }

    renderRounds(rounds) {
        this.graphs = [];
        let roundNumber = 0;
        const initialPoints = this.props.currentGame.game.settings.initial_points;
        this.cumulativeSum = [initialPoints, initialPoints, initialPoints, initialPoints];

        if (rounds.length > 0) {
            let roundObj = {};
            roundObj[this.props.currentGame.game.players[0].name] = this.cumulativeSum[0];
            roundObj[this.props.currentGame.game.players[1].name] = this.cumulativeSum[1];
            roundObj[this.props.currentGame.game.players[2].name] = this.cumulativeSum[2];
            roundObj[this.props.currentGame.game.players[3].name] = this.cumulativeSum[3];
            roundObj["round"] = "E";
            this.graphs.push(roundObj);

            return rounds.map(round_item => {
                ++roundNumber;
                this.cumulativeSum = this.cumulativeSum.map(
                    (value, i) => value + round_item.points[i][0] + round_item.points[i][1]
                );

                let roundObj = {};
                roundObj[this.props.currentGame.game.players[0].name] = this.cumulativeSum[0];
                roundObj[this.props.currentGame.game.players[1].name] = this.cumulativeSum[1];
                roundObj[this.props.currentGame.game.players[2].name] = this.cumulativeSum[2];
                roundObj[this.props.currentGame.game.players[3].name] = this.cumulativeSum[3];
                roundObj["round"] = round_item.round;
                this.graphs.push(roundObj);

                return (
                    <Round
                        key={roundNumber + round_item.round}
                        round_num={round_item.round}
                        riichi={round_item.riichi}
                        points={round_item.points}
                        outcome={round_item.outcome}
                        seats={round_item.data.seats}
                        cumulativeSum={this.cumulativeSum.slice()}
                        chonbo_type={this.props.currentGame.game.settings.chonbo_type}
                        players={this.props.currentGame.game.players}
                    />
                );
            });
        }
    }

    render() {
        const { rounds, loading } = this.props.roundsList;
        if (this.props.currentGame.loading && loading) {
            return <Loading />;
        }
        if (this.props.currentGame.game) {
            return (
                <GameInstanceView
                    currentGame={this.props.currentGame}
                    RoundComponents={this.renderRounds(rounds)}
                    graphs={this.graphs}
                />
            );
        } else return null;
    }
}

export default RoundsList;

RoundsList.propTypes = {
    currentGame: PropTypes.object,
    loadGameDetails: PropTypes.func,
    roundsList: PropTypes.object
};
