import {
    FETCH_GAMES,
    FETCH_GAMES_FAILURE,
    FETCH_GAMES_SUCCESS,
    RESET_GAMES
} from "../actions/gamesActions";

export default function reducer(state = { games: [], error: null, loading: false }, action) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_GAMES: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_GAMES_SUCCESS: {
            return {
                ...state,
                games: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_GAMES_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return { ...state, games: [], error: error, loading: false };
        case RESET_GAMES: // reset gamesList to initial state
            return { ...state, games: [], error: null, loading: false };
    }
}
