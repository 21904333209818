import React, { Component } from "react";
import PropTypes from "prop-types";
//TODO: create noTournamentsPlaceholder
import NoGamesPlaceholder from "../placeholders/NoGamesPlaceholder";
import Loading from "../placeholders/Loading";
import TournamentsListItem from "./TournamentsListItem";

class TournamentsList extends Component {
    componentDidMount() {
        this.props.fetchTournamentsList();
    }

    renderTournaments(tournaments) {
        if (tournaments.length > 0) {
            return tournaments.map(tournament => (
                <TournamentsListItem
                    key={tournament.id}
                    tournament_id={tournament.id}
                    location={tournament.location}
                    startsAt={tournament.starts_at}
                    name={tournament.name}
                    hanchans={tournament.hanchans}
                    tables={tournament.tables}
                />
            ));
        } else {
            return <NoGamesPlaceholder />;
        }
    }

    render() {
        const { tournaments, loading } = this.props.tournamentsList;

        if (loading) {
            return <Loading />;
        }

        return <div className="tournaments-list">{this.renderTournaments(tournaments)}</div>;
    }
}

export default TournamentsList;

TournamentsList.propTypes = {
    fetchTournamentsList: PropTypes.func.isRequired,
    tournamentsList: PropTypes.object
};
