import React from "react";
import PlaceholderTemplate from "./PlaceholderTemplate";

const UnderConstruction = () => {
    return (
        <PlaceholderTemplate
            header={"This page is under construction."}
            leading={"Come back later for more awesome stuff."}
            body={
                <div className="d-flex justify-content-center">
                    <i className="fas fa-hourglass-half fa-5x" />
                </div>
            }
        />
    );
};

export default UnderConstruction;
