import { connect } from "react-redux";
import { fetchGames } from "../actions/gamesActions";
import GamesList from "../components/games/GamesList";

const mapStateToProps = state => {
    return {
        gamesList: state.gamesData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchGames: () => {
            dispatch(fetchGames());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamesList);
