import React, { Component } from "react";
import PropTypes from "prop-types";

class RoundsHeaderView extends Component {
    render() {
        return (
            <thead>
                <tr className="tr--gray">
                    <th className="round-column" scope="col">
                        Round
                    </th>
                    <th scope="col">Outcome</th>
                    <th scope="col">{this.props.game.players[0].name}</th>
                    <th scope="col">{this.props.game.players[1].name}</th>
                    <th scope="col">{this.props.game.players[2].name}</th>
                    <th scope="col">{this.props.game.players[3].name}</th>
                </tr>
            </thead>
        );
    }
}

export default RoundsHeaderView;

RoundsHeaderView.propTypes = {
    game: PropTypes.object
};
