import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import RoundUtils from "../../utils/RoundUtils";
import RoundsHeaderView from "./RoundsHeaderView";
import UmaPointsView from "./UmaPointsView";
import SummaryPointsView from "./SummaryPointsView";
import PenaltyPointsView from "./PenaltyPointsView";

class TableView extends Component {
    render() {
        return (
            <div className="rounds">
                <h4 className="rounds__details">
                    <div className="rounds__header-text">
                        {
                            <Moment unix format="dddd, MMMM Do YYYY, HH:mm:ss">
                                {this.props.game.created_at}
                            </Moment>
                        }
                    </div>
                    <div className="rounds__header-text--divider"/>
                    <div className="rounds__header-text">
                        <span className="rounds__header-text--bold">
                            {RoundUtils.roundParser(this.props.game.round)}
                        </span>
                    </div>
                </h4>
                <table id="rounds" className="table" cellSpacing="0" width="100%">
                    <RoundsHeaderView game={this.props.game} />
                    <tbody>
                        {this.props.RoundsBody}
                        {this.props.game.round === "FINISH" && [
                            <UmaPointsView key={"uma"} game={this.props.game} />,
                            <PenaltyPointsView key={"penalties"} game={this.props.game} />,
                            <SummaryPointsView key={"summary"} game={this.props.game} />
                        ]}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TableView;

TableView.propTypes = {
    RoundsBody: PropTypes.array,
    game: PropTypes.object
};
