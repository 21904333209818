import React from "react";
import loading from "../Callback/loading.svg";

const Loading = () => {
    return (
        <div className="d-flex flex-grow center-loader">
            <img src={loading} alt="loading" />
        </div>
    );
};

export default Loading;
