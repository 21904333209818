import React, { Component } from "react";
import PropTypes from "prop-types";

class SummaryPointsView extends Component {
    finalPointsResult(gamePoints, playerSeat) {
        return gamePoints[playerSeat][0] + gamePoints[playerSeat][1] + gamePoints[playerSeat][2];
    }

    render() {
        return (
            <tr className="tr--gray">
                <td data-label="Final">Results</td>
                <td />
                <td data-label={this.props.game.players[0].name}>
                    {this.finalPointsResult(this.props.game.points, 0)}
                </td>
                <td data-label={this.props.game.players[1].name}>
                    {this.finalPointsResult(this.props.game.points, 1)}
                </td>
                <td data-label={this.props.game.players[2].name}>
                    {this.finalPointsResult(this.props.game.points, 2)}
                </td>
                <td data-label={this.props.game.players[3].name}>
                    {this.finalPointsResult(this.props.game.points, 3)}
                </td>
            </tr>
        );
    }
}

export default SummaryPointsView;

SummaryPointsView.propTypes = {
    game: PropTypes.object
};
