import axios from "axios";

export const FETCH_TOURNAMENTS = "FETCH_TOURNAMENTS";
export const FETCH_TOURNAMENTS_SUCCESS = "FETCH_TOURNAMENTS_SUCCESS";
export const FETCH_TOURNAMENTS_FAILURE = "FETCH_TOURNAMENTS_FAILURE";
export const RESET_TOURNAMENTS = "RESET_TOURNAMENTS";

export function fetchTournaments() {
    return dispatch => {
        dispatch(fetchingTournaments());
        return axios
            .get("/api/v1/tournaments")
            .then(response => {
                dispatch(fetchTournamentsSuccess(response.data));
            })
            .catch(error => {
                dispatch(fetchTournamentsFailure(error));
            });
    };
}

export function fetchingTournaments() {
    return {
        type: FETCH_TOURNAMENTS
    };
}

export function fetchTournamentsSuccess(tournaments) {
    return {
        type: FETCH_TOURNAMENTS_SUCCESS,
        payload: tournaments
    };
}

export function fetchTournamentsFailure(error) {
    return {
        type: FETCH_TOURNAMENTS_FAILURE,
        payload: error
    };
}
