import { connect } from "react-redux";
import { fetchRounds } from "../actions/roundActions";
import { fetchGame } from "../actions/gameActions";
import RoundsList from "../components/rounds/RoundsList";

const mapStateToProps = state => {
    return {
        roundsList: state.roundsList,
        currentGame: state.gameInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadGameDetails: () => {
            dispatch(fetchGame());
            dispatch(fetchRounds());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoundsList);
