import { connect } from "react-redux";
import JoinTeam from "../components/teams/JoinTeam";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JoinTeam);
