const AUTH_CONFIGS = {
    production: {
        domain: "mahjongtracker.eu.auth0.com",
        clientId: "N9QajhNHuduOG2LBVkwvgOHHROb6fDLB",
        callbackUrl: "https://mahjongtracker.com/app/callback",
        apiUrl: "https://mahjongtracker.com/api",
        logoutReturnURL: "https://mahjongtracker.com"
    },
    staging: {
        domain: "mahjongtracker-dev.eu.auth0.com",
        clientId: "4BowTQdd3KElyF7BT9wkGcOYL26oHw9B",
        callbackUrl: "https://dev.mahjongtracker.com/app/callback",
        apiUrl: "https://dev.mahjongtracker.com/api",
        logoutReturnURL: "https://dev.mahjongtracker.com"
    },
    local: {
        domain: "mahjongtracker-dev.eu.auth0.com",
        clientId: "4BowTQdd3KElyF7BT9wkGcOYL26oHw9B",
        callbackUrl: "http://localhost:8080/app/callback",
        apiUrl: "https://dev.mahjongtracker.com/api",
        logoutReturnURL: "http://localhost:8080"
    }
};

export { AUTH_CONFIGS };
