import React, { Component } from "react";
import PropTypes from "prop-types";

class PenaltyPointsView extends Component {
    render() {
        function doPenatiesExist(points) {
            return points[0][2] || points[1][2] || points[2][2] || points[3][2] > 0;
        }

        if (doPenatiesExist(this.props.game.points)) {
            return (
                <tr className="tr--gray">
                    <td data-label="Subtract">Penalties</td>
                    <td />
                    <td data-label={this.props.game.players[0].name}>
                        {this.props.game.points[0][2]}
                    </td>
                    <td data-label={this.props.game.players[1].name}>
                        {this.props.game.points[1][2]}
                    </td>
                    <td data-label={this.props.game.players[2].name}>
                        {this.props.game.points[2][2]}
                    </td>
                    <td data-label={this.props.game.players[3].name}>
                        {this.props.game.points[3][2]}
                    </td>
                </tr>
            );
        } else return null;
    }
}

export default PenaltyPointsView;

PenaltyPointsView.propTypes = {
    game: PropTypes.object
};
