import axios from "axios";
import getURLSegment from "../utils/urlUtils";

export const FETCH_RANKING = "FETCH_RANKING";
export const FETCH_RANKING_SUCCESS = "FETCH_RANKING_SUCCESS";
export const FETCH_RANKING_FAILURE = "FETCH_RANKING_FAILURE";
export const RESET_RANKING = "RESET_RANKING";

export function fetchTournamentRanking() {
    return dispatch => {
        dispatch(fetchingRanking());
        return axios
            .get("/api/v1/tournaments/" + getURLSegment(window.location.pathname, 3) + "/ranking")
            .then(response => {
                dispatch(fetchRankingSuccess(response.data));
            })
            .catch(error => {
                dispatch(fetchRankingFailure(error));
            });
    };
}

export function fetchingRanking() {
    return {
        type: FETCH_RANKING
    };
}

export function fetchRankingSuccess(ranking) {
    return {
        type: FETCH_RANKING_SUCCESS,
        payload: ranking
    };
}

export function fetchRankingFailure(error) {
    return {
        type: FETCH_RANKING_FAILURE,
        payload: error
    };
}
