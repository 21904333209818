import React, { Component } from "react";
import PropTypes from "prop-types";
import RoundUtils from "../../utils/RoundUtils";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

class PlayerRoundsComparison extends Component {
    parse_data(data) {
        data.map(item => (item["round"] = RoundUtils.roundParser(item["round"])));
        return data;
    }

    render() {
        if (this.props.rounds.length > 0) {
            return (
                <div className="game-statistics">
                    <p className="game-statistics__text">Player points across the rounds:</p>
                    <ResponsiveContainer width="100%" height={600}>
                        <LineChart
                            data={this.parse_data(this.props.rounds)}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="round" />
                            <YAxis type="number" domain={["auto", "auto"]} />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey={this.props.players[0].name}
                                strokeWidth={2}
                                stroke="#8884d8"
                                activeDot={{ r: 4 }}
                            />
                            <Line
                                type="monotone"
                                dataKey={this.props.players[1].name}
                                strokeWidth={2}
                                stroke="#82ca9d"
                                activeDot={{ r: 4 }}
                            />
                            <Line
                                type="monotone"
                                dataKey={this.props.players[2].name}
                                strokeWidth={2}
                                stroke="#0066ff"
                                activeDot={{ r: 4 }}
                            />
                            <Line
                                type="monotone"
                                dataKey={this.props.players[3].name}
                                strokeWidth={2}
                                stroke="#ff33cc"
                                activeDot={{ r: 4 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            );
        } else {
            return <div className="game-statistics__text">There&apos;s no rounds played yet.</div>;
        }
    }
}

export default PlayerRoundsComparison;

PlayerRoundsComparison.propTypes = {
    players: PropTypes.array,
    rounds: PropTypes.array
};
