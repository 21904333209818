import React, { Component } from "react";
import PropTypes from "prop-types";
import RegularRoundView from "./RegularRoundView";
import ChonboRoundView from "./ChonboRoundView";

class Round extends Component {
    render() {
        if (this.props.outcome === "Chonbo" && this.props.chonbo_type !== "ReverseMangan") {
            return (
                <ChonboRoundView
                    round_num={this.props.round_num}
                    outcome={this.props.outcome}
                    points={this.props.points}
                    cumulativeSum={this.props.cumulativeSum}
                    chonboSeats={this.props.seats}
                    players={this.props.players}
                />
            );
        }
        return (
            <RegularRoundView
                round_num={this.props.round_num}
                outcome={this.props.outcome}
                points={this.props.points}
                cumulativeSum={this.props.cumulativeSum}
                players={this.props.players}
            />
        );
    }
}

export default Round;

Round.propTypes = {
    chonbo_type: PropTypes.string,
    cumulativeSum: PropTypes.array,
    outcome: PropTypes.string,
    points: PropTypes.array,
    round_num: PropTypes.string,
    seats: PropTypes.array,
    players: PropTypes.array
};
