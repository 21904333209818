import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";

function TournamentsListItem(props) {
    return (
        <NavLink className="tournaments-list__item" to={"/app/tournaments/" + props.tournament_id}>
            <div id="tournament-info" className="col-md-12 align-self-start">
                <div className="text-left align-self-start font-weight-bold">{props.name}</div>
                <hr className="separator separator--dark" />
                <span className="text-left">
                    Location: {props.location.city}, {props.location.country}
                </span>
                <br />
                <span>
                    Starts at:{" "}
                    <Moment unix format="dddd, MMMM Do YYYY, HH:mm">
                        {props.startsAt}
                    </Moment>
                </span>
                <br />
                <span>
                    <span>{props.hanchans} hanchans </span>
                    <span>{props.tables} tables </span>
                </span>
            </div>
        </NavLink>
    );
}

export default TournamentsListItem;

TournamentsListItem.propTypes = {
    hanchans: PropTypes.number,
    startsAt: PropTypes.number,
    location: PropTypes.object,
    name: PropTypes.string.isRequired,
    tables: PropTypes.number,
    tournament_id: PropTypes.string
};
