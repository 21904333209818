import React from "react";
import PlaceholderTemplate from "./PlaceholderTemplate";

const UnderConstruction = () => {
    return (
        <PlaceholderTemplate
            header={"Tournament is not finished"}
            leading={"Achievements will be available after the tournament is finished."}
            body={
                <div className="d-flex justify-content-center">
                    <i className="fas fa-stopwatch fa-5x" />
                </div>
            }
        />
    );
};

export default UnderConstruction;
