import React from "react";
import PropTypes from "prop-types";

export const Tab = props => {
    return (
        <a
            className={`tab tab__header ${props.linkClassName} ${
                props.isActive ? "tab__header--active" : ""
            }`}
            onClick={event => {
                event.preventDefault();
                props.onClick(props.tabIndex);
            }}>
            <span className={"tab__icon"}>
                <i className={props.iconClassName} /> {props.label}
            </span>
        </a>
    );
};

Tab.propTypes = {
    iconClassName: PropTypes.string,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    linkClassName: PropTypes.string,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number
};
