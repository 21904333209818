import React from "react";
import Timer from "./Timer";

export const FullScreenTimer = ({ handleClose, show, startsAt, finishesAt, serverTime }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main full-screen-timer__wrapper">
                <a href="#" onClick={handleClose} className="close" />
                <Timer
                    startsAt={startsAt}
                    finishesAt={finishesAt}
                    serverTime={Number(serverTime)}
                    timerWrapperClass="full-screen-timer"
                    timerClass="full-screen-timer__time"
                    playAudio={show}
                />
            </section>
        </div>
    );
};
