import {
    FETCH_TOURNAMENT_ACHIEVEMENTS,
    FETCH_TOURNAMENT_ACHIEVEMENTS_FAILURE,
    FETCH_TOURNAMENT_ACHIEVEMENTS_SUCCESS,
    RESET_TOURNAMENT_ACHIEVEMENTS
} from "../actions/tournamentAchievementsActions";

export default function reducer(
    state = { tournamentAchievements: [], error: null, loading: false },
    action
) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_TOURNAMENT_ACHIEVEMENTS: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_TOURNAMENT_ACHIEVEMENTS_SUCCESS: {
            return {
                ...state,
                tournamentAchievements: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_TOURNAMENT_ACHIEVEMENTS_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return {
                ...state,
                tournamentAchievements: [],
                error: error,
                loading: false
            };
        case RESET_TOURNAMENT_ACHIEVEMENTS: // reset gamesList to initial state
            return {
                ...state,
                tournamentAchievements: [],
                error: null,
                loading: false
            };
    }
}
