import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import MainView from "./views/MainView";
import store from "./store";
import { Provider } from "react-redux";

ReactDOM.render(
    <Provider store={store}>
        <MainView />
    </Provider>,
    document.getElementById("app")
);
