import {
    FETCH_TOURNAMENT_TIMER,
    FETCH_TOURNAMENT_TIMER_SUCCESS,
    FETCH_TOURNAMENT_TIMER_FAILURE,
    RESET_TOURNAMENT_TIMER
} from "../actions/tournamentTimerActions";

export default function reducer(
    state = { tournamentTimer: { name: "" }, error: null, loading: true },
    action
) {
    let error;
    switch (action.type) {
        default:
            return state;
        case FETCH_TOURNAMENT_TIMER: {
            return { ...state, error: null, loading: true };
        }
        case FETCH_TOURNAMENT_TIMER_SUCCESS: {
            return {
                ...state,
                tournamentTimer: action.payload,
                error: null,
                loading: false
            };
        }
        case FETCH_TOURNAMENT_TIMER_FAILURE:
            error = action.payload || { message: action.payload.message }; //2nd one is network or server down errors
            return { ...state, tournamentTimer: [], error: error, loading: false };
        case RESET_TOURNAMENT_TIMER: // resets to initial state
            return { ...state, tournamentTimer: [], error: null, loading: false };
    }
}
