import React, { Component } from "react";
import PropTypes from "prop-types";
import RoundUtils from "../../utils/RoundUtils";

class RegularRoundView extends Component {
    render() {
        return <tr>
                <td data-label="Round">{RoundUtils.roundParser(this.props.round_num)}</td>
                <td data-label="Outcome">{this.props.outcome}</td>
                <td data-label={this.props.players[0].name}>
                    {RoundUtils.styler(this.props.points[0][0])}
                    {RoundUtils.styler(this.props.points[0][1])}
                    <div>
                        <b>{this.props.cumulativeSum[0]}</b>
                    </div>
                </td>
                <td data-label={this.props.players[1].name}>
                    {RoundUtils.styler(this.props.points[1][0])}
                    {RoundUtils.styler(this.props.points[1][1])}
                    <div>
                        <b>{this.props.cumulativeSum[1]}</b>
                    </div>
                </td>
                <td data-label={this.props.players[2].name}>
                    {RoundUtils.styler(this.props.points[2][0])}
                    {RoundUtils.styler(this.props.points[2][1])}
                    <div>
                        <b>{this.props.cumulativeSum[2]}</b>
                    </div>
                </td>
                <td data-label={this.props.players[3].name}>
                    {RoundUtils.styler(this.props.points[3][0])}
                    {RoundUtils.styler(this.props.points[3][1])}
                    <div>
                        <b>{this.props.cumulativeSum[3]}</b>
                    </div>
                </td>
            </tr>;
    }
}

export default RegularRoundView;

RegularRoundView.propTypes = {
    cumulativeSum: PropTypes.array,
    outcome: PropTypes.string,
    points: PropTypes.array,
    round_num: PropTypes.string
};
