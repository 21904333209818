import React from "react";

class RoundUtils {
    static roundParser(round) {
        if (round !== "FINISH") {
            return round.replace("E", "東").replace("S", "南");
        }
        return round;
    }

    static styler(points) {
        if (points > 0) {
            return <div style={{ color: "#0eb40d" }}>{points}</div>;
        } else if (points < 0) {
            return <div style={{ color: "#FF4444" }}>{points}</div>;
        }
        return <div>-</div>;
    }
}

export default RoundUtils;
