import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchTournament } from "../../actions/tournamentActions";
import NoGamesPlaceholder from "../placeholders/NoGamesPlaceholder";
import Loading from "../placeholders/Loading";
import Hanchan from "./Hanchan";
// import Timer from "../common/timer/Timer";

class HanchansList extends Component {
    componentDidMount() {
        this.props.fetchTournament();
    }

    renderHanchans(hanchans) {
        if (hanchans.length > 0) {
            return hanchans.map(hanchan => (
                <Hanchan key={hanchan.id} hanchanId={hanchan.id} tables={hanchan.tables} />
            ));
        } else {
            return <NoGamesPlaceholder />;
        }
    }

    render() {
        const { tournament, loading } = this.props.tournament;

        if (loading) {
            return <Loading />;
        }

        return <div className="accordion">{this.renderHanchans(tournament)}</div>;
    }
}

const mapStateToProps = state => {
    return {
        tournament: state.tournamentInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTournament: () => {
            dispatch(fetchTournament());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HanchansList);

HanchansList.propTypes = {
    fetchTournament: PropTypes.func
};
