import axios from "axios";
import getURLSegment from "../utils/urlUtils";

export const FETCH_ROUNDS = "FETCH_ROUNDS";
export const FETCH_ROUNDS_SUCCESS = "FETCH_ROUNDS_SUCCESS";
export const FETCH_ROUNDS_FAILURE = "FETCH_ROUNDS_FAILURE";
export const RESET_ROUNDS = "RESET_ROUNDS";

export function fetchRounds() {
    return dispatch => {
        dispatch(fetchingRounds());
        if (localStorage.getItem("access_token")) {
            return axios
                .get("/api/v1/games/" + getURLSegment(window.location.pathname, 4) + "/rounds", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("access_token")
                    }
                })
                .then(response => {
                    dispatch(fetchRoundsSuccess(response.data));
                })
                .catch(error => {
                    dispatch(fetchRoundsFailure(error));
                });
        } else {
            return axios
                .get("/api/v1/games/" + getURLSegment(window.location.pathname, 4) + "/rounds")
                .then(response => {
                    dispatch(fetchRoundsSuccess(response.data));
                })
                .catch(error => {
                    dispatch(fetchRoundsFailure(error));
                });
        }
    };
}

export function fetchingRounds() {
    return {
        type: FETCH_ROUNDS
    };
}

export function fetchRoundsSuccess(rounds) {
    return {
        type: FETCH_ROUNDS_SUCCESS,
        payload: rounds
    };
}

export function fetchRoundsFailure(error) {
    return {
        type: FETCH_ROUNDS_FAILURE,
        payload: error
    };
}
